import React, { useContext, useEffect } from "react";
import { NavItem } from "./components";
import { useRoutes } from "hookrouter";
import { Transactions } from "./customers";
import { Payers } from "./orders";
import { AnimatePresence, motion } from "framer-motion";
import { Logout } from "heroicons-react";
import { logout } from "../utils/fetch";
import Plans from "./users";
import { IconEnroll, IconOverview, IconClock } from "../assets/icons/hiking";
import "react-toastify/dist/ReactToastify.css";


const routes = {
  "/": () => <Logout />,
  "/customers": () => <Transactions />,
  "/users": () => <Plans />,
  "/orders": () => <Payers />,
};


export default function Main() {

  const routeResult = useRoutes(routes);

  return (
    <div className="flex w-full h-screen px-4 py-4 mx-auto">
      <div className="fixed h-full pr-10 pb-6">
        <div className="flex flex-col justify-between w-56 h-full text-white rounded-lg bg-firefly">
          <div className="flex flex-col items-center p-2 pt-6 pb-6">
            <img className="w-3/5 mb-4" src={`${process.env.PUBLIC_URL}/TRC_4r-logo_white.webp`} />
            <div className="px-1 py-0.5 bg-red-600 text-sm text-white font-semibold cursor-default">
              TRC ONE
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-center mb-60 mt-20">
            <motion.ul className="flex flex-col">

              <NavItem linkTo="/app/users" selected={routeResult.type === Plans}>
                <IconEnroll className="-ml-3 mr-2" />
                Users
              </NavItem>


              <NavItem linkTo="/app/customers" selected={routeResult.type === Transactions}>
                <IconClock className="-ml-3 mr-2" />
                Customers
              </NavItem>

              <NavItem linkTo="/app/orders" selected={routeResult.type === Payers}>
                <IconOverview className="-ml-3 mr-2" />
                Orders
              </NavItem>

            </motion.ul>
          </div>



          <div className="p-6 text-center">
            <div onClick={logout} className="flex justify-center cursor-pointer">
              <Logout className="pr-2" />
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-4 text-gray-800" style={{ marginLeft: "244px", paddingTop: "0" }}>

        {/* <Input disabled placeholder="Search Anything Here..." /> */}
        <AnimatePresence exitBeforeEnter>
          {routeResult || <div>Nothing Found</div>}
        </AnimatePresence>

      </div>
    </div>
  );

}




