import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { AddnewIcon, NoDataIcon } from '../assets/icons/hiking';
import { navigate, } from "hookrouter";
import { useNotifications } from './NotificationContext';

export default function Plans() {

  const [isFormVisible, setIsFormVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');


  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);

  const [triggerUserFetch, setTriggerUserFetch] = useState(0);
  const { showNotification } = useNotifications();

  useEffect(() => {
    const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';
    const type = "user";
    const nonce = "ef9da17f92274ec58a21fbcfc3885976";
    const eid = localStorage.getItem('default');

    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&search=' + encodeURIComponent(searchTerm) + '&nonce=' + nonce;

    setIsLoading(true);
    setMessage('');

    const fetchData = () => {
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.success && data.data.success && (data.data.count > 0 && data.data.response !== "NO")) {
            setUsers(data.data.response);
            setMessage('');
          } else if (data.error && data.error === "Session expired or invalid") {
            // navigate('');
          } else {
            setUsers([]);
            setMessage('No Data');
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setMessage('No Data');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const debouncedFetchData = debounce(fetchData, 500);

    debouncedFetchData();
  }, [searchTerm, triggerUserFetch]);






  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  const fetchUserDetails = (userId, userNonce) => {
    const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';
    const nonce = 'ef9da17f92274ec58a21fbcfc3885976'
    const eid = localStorage.getItem('default');
    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=user&id=' + encodeURIComponent(userNonce) + '&nonce=' + nonce;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('The network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success && data.data.response) {
          setSelectedUserDetails(data.data.response[0].user);
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  };

  function UserDetailsPopup({ user, onClose }) {
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-lg font-semibold mb-4">View Details: {user.username}</h2>
          <p>Username: {user.username}</p>
          <p>First Name: {user.first_name}</p>
          <p>Last Name: {user.last_name}</p>
          <p>Email: {user.email}</p>
          <p>Bright ID: {user.bright_id}</p>
          <div className="flex justify-end mt-6">
            <button className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  }



  const handleClosePopup = () => {
    setSelectedUserDetails(null);
  };


  const [formData, setFormData] = useState({
    userid: '',
    email: '',
    first_name: '',
    last_name: '',
    bright_id: '',

  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: formData.username,
      userid: formData.userid,
      email: formData.email,
      first_name: formData.first_name,
      last_name: formData.last_name,
      bright_id: formData.bright_id,
      channel_nonce: 'MTY1YjI2YzBjNzczNjk=',
      endpoint: 'v1/user/add',
      eid: localStorage.getItem('default'),
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data.response && data.response.nonce) {
          const Notificationpayload = {
            response: true,
            subject: "User",
            operation: "Created",
            context: "",
          };
          showNotification(Notificationpayload);
          setTriggerUserFetch(prev => prev + 1);
        }
        else {
          const Notificationpayload = {
            response: false,
            subject: "User",
            operation: "Creating",
            context: "",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "User",
          operation: "Creating",
          context: "",
        };
        showNotification(Notificationpayload);
      });
  };


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container-tokens"
    >
      {isFormVisible ? (
        <div>

          <div className="header-tokens flex justify-between">

            {/* elements on the left */}
            <div className="flex items-center">
              <h1 className="activity-log-title mr-4">Users List</h1>
              <input
                type="text"
                placeholder="Search by name"
                className="input-tokens"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* elements on the right */}
            <div className="flex items-center">
              <button className="filter-button-token mr-2">
                Filter By
              </button>
              <button className="add-new-token" onClick={() => setIsFormVisible(false)}>
                <AddnewIcon className="mr-1" />
                Create a User
              </button>
            </div>
          </div>

          <div className="tokens-table-container">
            <table className="tokens-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td
                      className="text-blue-500 hover:underline cursor-pointer hover:text-blue-700"
                      onClick={() => fetchUserDetails(user.id, user.user.nonce)}
                    >
                      {user.user.username}
                    </td>
                    <td>{user.user.first_name}</td>
                    <td>{user.user.last_name}</td>
                    <td>{user.user.email}</td>
                  </tr>
                ))}
              </tbody>
              {selectedUserDetails && (
                <UserDetailsPopup user={selectedUserDetails} onClose={handleClosePopup} />
              )}

            </table>
            {message && (
              <div className="flex flex-col items-center justify-center h-64">
                <NoDataIcon />
                <div className="text-lg font-semibold text-gray-600">{message}</div>
              </div>
            )}
            {isLoading && (
              <div className="flex flex-col items-center justify-center h-64">
                <div>Loading...</div>
              </div>
            )}

          </div>
        </div>

      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center w-full min-h-screen pt-10"
        >
          <form onSubmit={handleSubmit} className="container mx-auto p-6 custom-width">

            <div>
              <div className="flex justify-between items-center py-6">
                <div>
                  <h1 className="token-title">Create a User</h1>
                  <span className="create-user-description"> (*)fields are mandatory to fill</span>
                </div>
              </div>


              {/* Full name and email address */}
              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label htmlFor="tokenName" className="text-sm font-semibold">Username</label>
                  <input
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                  <label htmlFor="userName" className="text-sm font-semibold">User Id</label>
                  <input
                    name="userid"
                    value={formData.userid}
                    onChange={handleChange}
                    className="w-full input-style"
                  />
                </div>
              </div>


              {/* email and first name */}
              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label htmlFor="tokenName" className="text-sm font-semibold">Email</label>
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                  <label htmlFor="userName" className="text-sm font-semibold">First Name</label>
                  <input
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    className="w-full input-style"
                  />
                </div>
              </div>

              {/* last name and brihgt id */}
              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label htmlFor="tokenName" className="text-sm font-semibold">Last Name</label>
                  <input
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                  <label htmlFor="userName" className="text-sm font-semibold">Bright Id</label>
                  <input
                    name="bright_id"
                    value={formData.bright_id}
                    onChange={handleChange}
                    className="w-full input-style"
                  />
                </div>
              </div>
              <button type="submit" className="save-and-continue text-sm font-semibold">Save</button>
            </div>
          </form>

        </motion.div >
      )}
    </motion.div >
  );

}
