import React, { useState, useEffect } from "react";
import { navigate, } from "hookrouter";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);





  function handleLogin(e) {
    e.preventDefault();

    const eid = "MTY1YTU0YzI2YTY4ODI=";
    const type = "ip_address";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const clientIP = data.ip;

        const payload = {
          username: email,
          password: password,
          ip: clientIP,
          endpoint: "v1/login/verify",

        };

        return fetch('/admin/postData.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
      })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {

          const defaultNonce = data.response.default;
          const time_offset = data.response.time_offset;
          const userNonce = data.response.api

          localStorage.setItem('username', email); // from payload
          localStorage.setItem('time_offset', time_offset);
          localStorage.setItem('default', defaultNonce);
          localStorage.setItem('nonce', userNonce);

          window.location.href = '/app/users';

        }
      })

      .catch((error) => {
        console.error('Error:', error);
        setError(true);
      });
  }





  return (
    <div className="flex items-center justify-center h-screen">
      <form
        onSubmit={handleLogin}
        className="flex flex-col self-center px-8 pt-6 pb-8 m-12 mb-4 bg-white rounded shadow-md"
      >
        <div>{error && "Your credentials are incorrect"}</div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-grey-darker"
            for="username"
          >
            Username
          </label>
          <input
            className="w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker"
            id="username"
            onChange={(e) => setEmail(e?.target?.value)}
            type="text"
            placeholder="Username"
          />
        </div>
        <div className="mb-6">
          <label
            className="block mb-2 text-sm font-bold text-grey-darker"
            for="password"
          >
            Password
          </label>
          <input
            className="w-full px-3 py-2 mb-3 border rounded shadow appearance-none border-red text-grey-darker"
            id="password"
            type="password"
            onChange={(e) => setPassword(e?.target?.value)}
            placeholder="******************"
          />
          <p className="text-xs italic text-red">Please choose a password.</p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="px-4 py-2 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
            type="submit"
          >
            Sign In
          </button>
          {/* <a
            className="inline-block text-sm font-bold align-baseline text-blue hover:text-blue-darker"
            href="#"
          >
            Forgot Password?
          </a> */}
        </div>
      </form>
    </div>
  );
}

