import React, { createContext, useContext, useEffect, useState } from 'react';

const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
    const [notificationCenter, setNotificationCenter] = useState(null);

    useEffect(() => {
        const nc = new NotificationCenter();
        setNotificationCenter(nc);

    }, []);

    const showNotification = (params) => {
        if (notificationCenter) {
            notificationCenter.spawnNoteWithParams(params);
        }
    };

    class NotificationCenter {
        constructor() {
            this.items = [];
            this.itemsToKill = [];
            this.messages = NotificationMessages();
            this.shownMessages = new Set();
            this.killTimeout = null;
            this.currentIndex = 0;
        }
        spawnNoteIfNeeded() {
            this.spawnNote();
        }

        spawnNote() {
            if (this.currentIndex >= this.messages.length) {
                console.log("Reiniciando mensajes.");
                this.currentIndex = 0;
            }

            if (this.items.length >= 3) {
                const firstNoteId = this.items[0].id;
                this.killNote(firstNoteId, { target: { getAttribute: () => firstNoteId } });
            }

            const message = this.messages[this.currentIndex];
            this.shownMessages.add(message.id);
            this.currentIndex++;

            const id = this.random(0, 2 ** 32, true).toString(16);
            const note = new Notification({
                id: `note-${id}`,
                icon: message.icon,
                title: message.title,
                context: message.context,
                actions: message.actions
            });
            const transY = 100 * this.items.length;

            note.el.style.transform = `translateY(${transY}%)`;
            note.el.addEventListener("click", this.killNote.bind(this, note.id));

            this.items.push(note);
        }

        spawnNotes(amount) {
            let count = typeof amount === "number" ? amount : this.random(1, 5, true);

            while (count--)
                this.spawnNote();
        }
        killNote(id, e) {
            const note = this.items.find(item => item.id === id);
            const tar = e.target;

            if (note && tar.getAttribute("data-dismiss") === id) {
                note.el.classList.add("notification--out");
                this.itemsToKill.push(note);

                clearTimeout(this.killTimeout);

                this.killTimeout = setTimeout(() => {
                    this.itemsToKill.forEach(itemToKill => {
                        document.body.removeChild(itemToKill.el);

                        const left = this.items.filter(item => item.id !== itemToKill.id);
                        this.items = [...left];
                    });

                    this.itemsToKill = [];

                    this.shiftNotes();
                }, note.killTime);
            }
        }

        shiftNotes() {
            this.items.forEach((item, i) => {
                const transY = 100 * i;
                item.el.style.transform = `translateY(${transY}%)`;
            });
        }
        random(min, max, round = false) {
            const percent = crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
            const relativeValue = (max - min) * percent;

            return min + (round === true ? Math.round(relativeValue) : +relativeValue.toFixed(2));
        }


        spawnNoteWithParams(params) {
            if (this.items.length >= 3) {
                const firstNoteId = this.items[0].id;
                this.killNote(firstNoteId, { target: { getAttribute: () => firstNoteId } });
            }

            const { icon, title, context, actions } = this.constructMessageFromParams(params);
            const id = this.random(0, 2 ** 32, true).toString(16);

            const note = new Notification({
                id: `note-${id}`,
                icon,
                title,
                context,
                actions,
            });
            const transY = 100 * this.items.length;

            note.el.style.transform = `translateY(${transY}%)`;
            note.el.addEventListener("click", this.killNote.bind(this, note.id));

            this.items.push(note);
            document.body.appendChild(note.el);
        }



        constructMessageFromParams(params) {
            let title;
            const icon = params.response ? "success" : "error";
            const context = params.context || "";
            const actions = ["OK"];

            if (params.response) {
                title = `${params.subject} ${params.operation} Successfully`;
            } else {
                title = `Error ${params.operation} ${params.subject}`;
            }

            return { icon, title, context, actions };
        }

    }


    class Notification {
        constructor(args) {
            this.args = args;
            this.el = null;
            this.id = null;
            this.killTime = 300;
            this.init(args);
        }
        init(args) {
            const { id, icon, title, context, actions } = args;
            const block = "notification";
            const parent = document.body;
            const xmlnsSVG = "http://www.w3.org/2000/svg";
            const xmlnsUse = "http://www.w3.org/1999/xlink";

            const note = this.newEl("div");
            note.id = id;
            note.className = block;
            parent.insertBefore(note, parent.lastElementChild);

            const box = this.newEl("div");
            box.className = `${block}__box`;
            note.appendChild(box);

            const content = this.newEl("div");
            content.className = `${block}__content`;
            box.appendChild(content);

            const _icon = this.newEl("div");
            _icon.className = `${block}__icon`;
            content.appendChild(_icon);

            const iconSVG = this.newEl("svg", xmlnsSVG);
            iconSVG.setAttribute("class", `${block}__icon-svg`);
            iconSVG.setAttribute("role", "img");
            iconSVG.setAttribute("aria-label", icon);
            iconSVG.setAttribute("width", "32px");
            iconSVG.setAttribute("height", "32px");
            _icon.appendChild(iconSVG);

            const iconUse = this.newEl("use", xmlnsSVG);
            iconUse.setAttributeNS(xmlnsUse, "href", `#${icon}`);
            iconSVG.appendChild(iconUse);

            const text = this.newEl("div");
            text.className = `${block}__text`;
            content.appendChild(text);

            const _title = this.newEl("div");
            _title.className = `${block}__text-title`;
            _title.textContent = title;
            text.appendChild(_title);

            if (context) {
                const _context = this.newEl("div");
                _context.className = `${block}__text-context`;
                _context.textContent = context;
                text.appendChild(_context);
            }

            const btns = this.newEl("div");
            btns.className = `${block}__btns`;
            box.appendChild(btns);

            actions.forEach(action => {
                const btn = this.newEl("button");
                btn.className = `${block}__btn`;
                btn.type = "button";
                btn.setAttribute("data-dismiss", id);

                const btnText = this.newEl("span");
                btnText.className = `${block}__btn-text`;
                btnText.textContent = action;

                btn.appendChild(btnText);
                btns.appendChild(btn);
            });

            this.el = note;
            this.id = note.id;
        }
        newEl(elName, NSValue) {
            if (NSValue)
                return document.createElementNS(NSValue, elName);
            else
                return document.createElement(elName);
        }
    }

    function NotificationMessages() {
        return [
            {
                icon: "success", // error || success
                title: "Notification 1", //User Updated Succesfully
                context: "NO CHANNELS SELECTED",
                actions: ["OK"]
            },
            {
                icon: "error", // error || success
                title: "Notification 2",
                context: "NO CHANNELS SELECTED",
                actions: ["OK"]
            },
            {
                icon: "success", // error || success
                title: "Notification 3",
                context: "NO CHANNELS SELECTED",
                actions: ["OK"]
            },
        ];
    }




    return (
        <NotificationContext.Provider value={{ showNotification }}>
            <div class="fixed top-0 left-0">
                <svg display="none">
                    <symbol id="error" viewBox="0 0 32 32" >
                        <circle r="15" cx="16" cy="16" fill="none" stroke="var(--error-color, hsl(13,90%,55%))" stroke-width="2" />
                        <line x1="10" y1="10" x2="22" y2="22" stroke="var(--error-color, hsl(13,90%,55%))" stroke-width="2" stroke-linecap="round" />
                        <line x1="22" y1="10" x2="10" y2="22" stroke="var(--error-color, hsl(13,90%,55%))" stroke-width="2" stroke-linecap="round" />
                    </symbol>
                    <symbol id="success" viewBox="0 0 32 32" >
                        <circle r="15" cx="16" cy="16" fill="none" stroke="var(--success-color, hsl(93,90%,40%))" stroke-width="2" />
                        <polyline points="9,18 13,22 23,12" fill="none" stroke="var(--success-color, hsl(93,90%,40%))" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </symbol>
                </svg>
            </div>
            {children}
        </NotificationContext.Provider>
    );
};


export const useNotifications = () => useContext(NotificationContext);